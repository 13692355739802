// Import Bootstrap's functions and variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Override the $font-sizes map
$font-sizes: (
  1: 3rem,    // .fs-1 class will have a font-size of 3rem
  2: 2.5rem,  // .fs-2 class will have a font-size of 2.5rem
  3: 2rem,    // .fs-3 class will have a font-size of 2rem
  4: 1.5rem,  // .fs-4 class will have a font-size of 1.5rem
  5: 1.25rem, // .fs-5 class will have a font-size of 1.25rem
  6: 0.5rem,    // .fs-6 class will have a font-size of 1rem
);

// Override the Bootstrap default font-family
$font-family-base: 'Fira Code', sans-serif;
@import "~bootstrap/scss/bootstrap";


